import { React, useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Modal } from 'react-bootstrap';
import es from 'date-fns/locale/es';
import axios from 'axios';
import { parseISO } from 'date-fns';

import { useTranslation } from 'react-i18next';

const DatosPropiedad = ({ propiedad, servicios, politicas, diasbloqueados, primerdialibre, calendario, lang, config, extras, llegada, salida, adultos, ninos, bebes, configweb, moneda, abreviaturamoneda }) => {

    const { t } = useTranslation();

    registerLocale('es', es);

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    let results = [];
    let results2 = [];

    let entr = llegada != null ? parseISO(llegada) : null;
    let sali = salida != null ? parseISO(salida) : null;

    let primdialibre = parseISO(primerdialibre);

    const [showprop, setShowprop] = useState('none');
    const handleShowprop = () => {
        if (showprop == "none") setShowprop("block");
        else setShowprop("none");
    }
    const [dateRange, setDateRange] = useState([entr, sali])
    const [startDate, endDate] = dateRange;


    let botones = document.querySelectorAll(".boton");

    const cuandoSeHaceClick = function (evento) {

        var nuevo_mes = this.getAttribute("data-nuevo-mes");
        var nuevo_ano = this.getAttribute("data-nuevo-ano");

        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/calendario0k', {
            idPropiedad: propiedad.id,
            nuevo_mes: nuevo_mes,
            nuevo_ano: nuevo_ano,
            bloqueos: JSON.stringify(diasbloqueados),
            lang: lang
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                document.querySelectorAll(".calendario")[0].innerHTML = response.data;
                botones = document.querySelectorAll(".boton");
                botones.forEach(boton => {
                    boton.addEventListener("click", cuandoSeHaceClick);
                })
            })

    }

    botones.forEach(boton => {
        boton.addEventListener("click", cuandoSeHaceClick);
    })




    let textopersonas = t("Personas");

    if (adultos > 0 || ninos > 0 || bebes > 0) {
        textopersonas = "";
        if (adultos > 0)
            textopersonas += " " + adultos + " " + t("Adultos");
        if (ninos > 0)
            textopersonas += " " + ninos + " " + t("Niños");
        if (bebes > 0)
            textopersonas += " " + bebes + " " + t("Bebés");
    }

    const numpersonas = useRef(null);

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
        setShowModal(!showModal);
    }

    let dias = [];

    {
        diasbloqueados.forEach((undia, index) => {
            dias.push(new Date(undia))
        })
    }

    {/* *************** */ }

    const [countAdults, setCountAdults] = useState(parseInt(adultos));
    const [countNinos, setCountNinos] = useState(parseInt(ninos));
    const [countBebes, setCountBebes] = useState(parseInt(bebes));

    const handleSumaAdultos = () => {
        if (countAdults + countNinos < propiedad.capacidad_maxima) {
            setCountAdults(ca => ca + 1);
            handleEtiqueta(parseInt(countAdults) + 1, parseInt(countNinos), parseInt(countBebes));
        }
    }

    const handleRestaAdultos = () => {
        if (countAdults > 0) {
            setCountAdults(ca => ca - 1);
            handleEtiqueta(parseInt(countAdults) - 1, parseInt(countNinos), parseInt(countBebes));
        }
    }

    const handleSumaNinos = () => {
        if (countNinos + countAdults < propiedad.capacidad_maxima) {
            setCountNinos(cn => cn + 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos) + 1, parseInt(countBebes));
        }
    }

    const handleRestaNinos = () => {
        if (countNinos > 0) {
            setCountNinos(cn => cn - 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos) - 1, parseInt(countBebes));
        }

    }

    const handleSumaBebes = () => {
        if (countBebes < propiedad.capacidad_maxima) {
            setCountBebes(cb => cb + 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos), parseInt(countBebes) + 1);
        }
    }

    const handleRestaBebes = () => {
        if (countBebes > 0) {
            setCountBebes(cb => cb - 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos), parseInt(countBebes) - 1);
        }
    }

    const handleEtiqueta = (adults, ninos, bebes) => {

        let frase = t("Personas");
        if (adults > 0) frase = " " + adults + " " + t("Adultos");
        if (ninos > 0) frase += " " + ninos + " " + t("Niños");
        if (bebes > 0) frase += " " + bebes + " " + t("Bebés");

        numpersonas.current.textContent = frase;
    }

    {/* *************** */ }

    const [dataRes, setDataRes] = useState("<p></p>");

    const handleSubmit = (e) => {
        e.preventDefault()

        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/haydispo', {
            id: propiedad.id,
            range: e.target.daterange.value,
            personas: Number(countAdults) + Number(countNinos),
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                const solucion = response.data;
                if (solucion.Error == "OK") {

                    let noches = 0;
                    let estancia = 0;
                    let eltotal = 0;
                    var textoextras = "";

                    const precios = solucion.precios[0];
                    const extras = solucion.extras.relacion_extras;

                    if (propiedad.by_request == 0) {
                        for (let j = 0; j < precios.calendario_sin_otas.length; j++) {
                            //estancia += precios.calendario_sin_otas[j].precio;
                            noches++;
                        }
                        estancia = precios.suma * 0.01;

                        eltotal = eltotal + estancia;

                        for (let j = 0; j < extras.length; j++) {
                            if (extras[j].obligatorio == 1) {
                                textoextras += "  <div class='row mb-3'>" +
                                    "<div class='col-8'>" +
                                    extras[j].nombre +
                                    "</div>" +
                                    "<div class='col-4 text-end'>" +
                                    extras[j].precio * 0.01 + moneda +
                                    "</div>" +
                                    "</div>";
                                eltotal = eltotal + (extras[j].precio * 0.01);
                            }

                        }
                    }

                    if (propiedad.by_request == 1) {
                        var formreserva =
                            "<div class='row mb-3'>" +
                            "<div class='col-8'>" +
                            solucion.noches + " " + t("Noches") + " / " + (Number(countAdults) + Number(countNinos)) + " " + t("Personas") +
                            "</div>" +
                            "<div class='col-4 text-end'>" +
                            "</div>" +
                            "</div>" +
                            "<div>" +
                            "<a href='/" + preidioma + t("peticion") + "?idpropiedad=" + propiedad.id + "&amp;daterange=" + e.target.daterange.value + "&amp;adultos=" + Number(countAdults) + "&amp;ninos=" + Number(countNinos) + "&amp;bebes=" + Number(countBebes) + "&amp;solorequest=1' class='btn btn-primary btn-icon mt-2 w100' style='background:" + configweb.color_corporativo_web + "; border: 1px solid " + configweb.color_corporativo_web + "'>" + t("Petición") + "</a>";
                        "</div>"
                    }
                    else {
                        var formreserva =
                            "<div class='row mb-3'>" +
                            "<div class='col-8'>" +
                            noches + " " + t("Noches") + " / " + (Number(countAdults) + Number(countNinos)) + " " + t("Personas") +
                            "</div>" +
                            "<div class='col-4 text-end'>" +
                            estancia.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + moneda +
                            "</div>" +
                            "</div>" +
                            textoextras +
                            "<div class='linia mt-3 mb-3'>" +
                            "</div>" +
                            "<div class='row'>" +
                            "<div class='col-8 fw-bolder'>" +
                            t("Total") +
                            "</div>" +
                            "<div class='col-4 text-end fw-bolder'>" +
                            eltotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + moneda +
                            "</div>" +
                            "</div>" +
                            "<div>" +
                            "<a href='/" + preidioma + t("reserva") + "?idpropiedad=" + propiedad.id + "&amp;daterange=" + e.target.daterange.value + "&amp;adultos=" + Number(countAdults) + "&amp;ninos=" + Number(countNinos) + "&amp;bebes=" + Number(countBebes) + "&amp;solorequest=0&amp;tarifa=" + precios.id + "' class='btn btn-primary btn-icon mt-2 w100' style='background:" + configweb.color_corporativo_web + "; border: 1px solid " + configweb.color_corporativo_web + "'>" + t("Reservar") + "</a>";
                        "</div>"
                    }

                    setDataRes(
                        formreserva
                    );
                }
                else {
                    setDataRes("<p>" + solucion.Error + "</p>");
                }
            });
    }

    useEffect(() => {
        if (adultos > 0 && entr != null && sali != null) {
            let token = document.getElementById('meta_token').getAttribute('content');

            const fecha = new Date(llegada);
            const fecha2 = new Date(salida);
            const opciones = { day: '2-digit', month: '2-digit', year: 'numeric' };
            const llegadaFormateada = fecha.toLocaleDateString('es-ES', opciones);
            const salidaformateada = fecha2.toLocaleDateString('es-ES', opciones);

            const result = axios.post('/api/v1/rates', {
                id: propiedad.id,
                range: llegadaFormateada + " - " + salidaformateada,
                personas: Number(countAdults) + Number(countNinos),
            }, {
                headers: {
                    'content-type': 'text/json',
                    'X-CSRF-TOKEN': token
                }
            })
                .then((response) => {
                    const solucion = response.data;
                    if (solucion.Error == "OK") {
                        const precios = solucion.precios[0];

                        let estancia = 0;
                        let eltotal = 0;
                        let noches = 0;
                        for (let j = 0; j < precios.calendario_sin_otas.length; j++) {
                            estancia += precios.calendario_sin_otas[j].precio;
                            noches++;
                        }
                        estancia = estancia * 0.01;

                        eltotal = eltotal + estancia;

                        const extras = solucion.extras.relacion_extras;

                        var textoextras = "";

                        for (let j = 0; j < extras.length; j++) {
                            if (extras[j].obligatorio == 1) {
                                textoextras += "  <div class='row mb-3'>" +
                                    "<div class='col-8'>" +
                                    extras[j].nombre +
                                    "</div>" +
                                    "<div class='col-4 text-end'>" +
                                    extras[j].precio * 0.01 + moneda +
                                    "</div>" +
                                    "</div>";
                                eltotal = eltotal + (extras[j].precio * 0.01);
                            }

                        }

                        if (propiedad.by_request == 1) {
                            var urlreserva = "<a href='/" + preidioma + t("peticion") + "?idpropiedad=" + propiedad.id + "&amp;daterange=" + e.target.daterange.value + "&amp;adultos=" + Number(countAdults) + "&amp;ninos=" + Number(countNinos) + "&amp;bebes=" + Number(countBebes) + "&amp;solorequest=1' class='btn btn-primary btn-icon mt-2 w100' style='background:" + configweb.color_corporativo_web + "; border: 1px solid " + configweb.color_corporativo_web + "'>" + t("Petición") + "</a>";
                        }
                        else {
                            var urlreserva = "<a href='/" + preidioma + t("reserva") + "?idpropiedad=" + propiedad.id + "&amp;daterange=" + e.target.daterange.value + "&amp;adultos=" + Number(countAdults) + "&amp;ninos=" + Number(countNinos) + "&amp;bebes=" + Number(countBebes) + "&amp;solorequest=0&amp;tarifa=" + precios.id + "' class='btn btn-primary btn-icon mt-2 w100' style='background:" + configweb.color_corporativo_web + "; border: 1px solid " + configweb.color_corporativo_web + "'>" + t("Reservar") + "</a>";
                        }

                        setDataRes(
                            "<div class='row mb-3'>" +
                            "<div class='col-8'>" +
                            noches + " " + t("Noches") + " / " + (Number(countAdults) + Number(countNinos)) + " " + t("Personas") +
                            "</div>" +
                            "<div class='col-4 text-end'>" +
                            estancia.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + moneda +
                            "</div>" +
                            "</div>" +
                            textoextras +
                            "<div class='linia mt-3 mb-3'>" +
                            "</div>" +
                            "<div class='row'>" +
                            "<div class='col-8 fw-bolder'>" +
                            t("Total") +
                            "</div>" +
                            "<div class='col-4 text-end fw-bolder'>" +
                            eltotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + moneda +
                            "</div>" +
                            "</div>" +
                            urlreserva +
                            "</div>"
                        );
                    }
                    else {
                        setDataRes("<p>" + solucion.Error + "</p>");
                    }
                });
        }
    }
        , [])

    {/* *************** */ }

    {
        servicios.forEach((unservicio, index) => {
            results = results.concat(
                <div className="col-md-4 col-sm-4 col-xs-6 altura30" title={unservicio.elnombre} data-original-title={unservicio.elnombre} key={"s" + index}>
                    <i className="fa fa-check fa-1-5x"></i> <span className="with-icon">{unservicio.elnombre}</span>
                </div>
            )
        })
    }

    {/* *************** */ }

    return (
        <div className="container">
            <div className="row">
                <div className="order-last mb-5 col-md-7 col-lg-8 order-sm-first">

                    <div className="mb-5 detail-content-for-sticky-menu">

                        <div id="detail-content-sticky-nav-05" className="pt-4">
                            <div className="calendario" align="center">
                                <div dangerouslySetInnerHTML={{ __html: calendario }}></div>
                            </div>

                            <ul className="clearfix map-neighbour-label">
                                <li className="color-04">
                                    {t("Ocupado")}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="linia"></div>

                    <div className="mt-5 mb-4 section-title-3">
                        <h3>{config.nombre_comercial}</h3>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="featured-item-2">
                                <div className="icon">
                                    <i className="fa-duotone fa-credit-card"></i>
                                </div>
                                <p>{t("Protección de pago 100%")}</p>
                            </div>
                        </div>
                    </div>

                    <div className="mb-40"></div>
                </div>

                <div className="col-md-5 col-lg-4 detail-right-sidebar">
                    <div className="mt-4 caixa-preus sticky-top">
                        <div className="price"></div>
                        <div className="inner">
                            <form className="gap-10 row" name="buscaDisponibilidad" method="GET" id="buscaDisponibilidad" onSubmit={handleSubmit}>
                                <input type="hidden" id="lang" name="lang" value={lang} />
                                <input type="hidden" id="moneda" name="moneda" value={abreviaturamoneda} />
                                <div className="m-0 border-0 bd-example">
                                    <div className="mb-3">
                                        <DatePicker
                                            isClearable={true}
                                            onChange={(update) => {
                                                setDateRange(update);
                                            }}
                                            excludeDates={dias}
                                            className="form-control mw201 cpointer"
                                            autoComplete="off"
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange={true}
                                            monthsShown={2}
                                            dateFormat="dd/MM/yyyy"
                                            minDate={primdialibre}
                                            placeholderText={t("Entrada - Salida")}
                                            locale={lang}
                                            name="daterange"
                                            fixedHeight
                                        >
                                        </DatePicker>
                                    </div>

                                    <div className="mb-3">
                                        <div onClick={handleShowprop} className="form-control numpersonas mw201" ref={numpersonas}>{textopersonas}</div>

                                        {/* ********* */}

                                        <div className="formpersonas" style={{ display: showprop }} onBlur={handleShowprop} tabIndex="0" onMouseLeave={handleShowprop}>
                                            <div className="mt-2 row">
                                                <label className="col-md-6 col-6 etiqueta-formulari"> {t("Adultos")}<div className="mini-etiqueta-formulari">({t("Desde_12_años")})</div></label>
                                                <div className="col-md-4 col-6">
                                                    <div className="mt-4 input-group">
                                                        <span className="input-group-btn">
                                                            <button className="btn btn-xs btn-number boto-cercle" type="button" data-type="minus" data-field="my_multi_select_adultos" onClick={handleRestaAdultos}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                        <input type="text" name="adultos" className="form-control input-number numero-persones" value={countAdults} min="0" max="10" id="numadultos" readOnly={true} />
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_adultos" onClick={handleSumaAdultos}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }} >
                                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="linia"></div>

                                            <div className="mt-3 row">
                                                <label className="col-md-6 col-6 etiqueta-formulari"> {t("Niños")}<div className="mini-etiqueta-formulari">({t("De_2_a_12_años")})</div></label>
                                                <div className="col-md-4 col-6">
                                                    <div className="mt-4 input-group">
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="minus" data-field="my_multi_select_ninos" onClick={handleRestaNinos}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                        <input type="text" name="ninos" className="form-control input-number numero-persones" value={countNinos} min="0" max="10" id="numninos" readOnly={true} />
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_ninos" onClick={handleSumaNinos}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="linia"></div>

                                            <div className="mt-3 row">
                                                <label className="col-md-6 col-6 etiqueta-formulari"> {t("Bebés")}<div className="mini-etiqueta-formulari">{t("De_0_a_2_años")}</div></label>
                                                <div className="col-md-4 col-6">
                                                    <div className="mt-4 input-group">
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" datatype="minus" datafield="my_multi_select_bebes" onClick={handleRestaBebes}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                        <input type="text" name="bebes" className="form-control input-number numero-persones" value={countBebes} min="0" max="10" id="numbebes" readOnly={true} />
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_bebes" onClick={handleSumaBebes}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ********* */}
                                    </div>
                                </div>

                                <div className="gap-2 d-grid">
                                    <button type="submit" className="mt-2 btn btn-primary btn-icon" style={{ background: "" + configweb.color_corporativo_web + "", border: "1px solid" + configweb.color_corporativo_web }}>
                                        {propiedad.by_request == 1
                                            ? t("Buscar disponibilidad") + " " + t("Petición")
                                            : t("Buscar disponibilidad")
                                        }
                                    </button>
                                    <span className="text-center gris"> {t("Vas a rentar la propiedad completa")}.</span>
                                </div>

                                <div className="mb-1 clear"></div>

                                <div className="mt-3">
                                    <div dangerouslySetInnerHTML={{ __html: dataRes }}></div>
                                </div>
                                <div className="clear"></div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default DatosPropiedad;
