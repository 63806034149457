import React ,  { useState, useRef, useEffect }from "react";
import {Container, Form, Button } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import {parseISO} from 'date-fns';
import CustomSelect from '../Compartido/CustomSelect.jsx';
import axios from 'axios';

import { useTranslation } from 'react-i18next';

const Buscador = ({destino, destinos, tiposcasas, adultos, ninos, bebes, llegada, salida, tipocasa, lang, config, capacidad_maxima, barrios, barrio}) => {

    const { t } = useTranslation();

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    registerLocale('es', es)

    const results=[];
    const results2=[];
    const results3=[];

    let entr = llegada!=null ? parseISO(llegada) : null;
    let sali = salida!=null ? parseISO(salida) : null;

    const [dateRange, setDateRange] = useState([entr, sali]);

    const [startDate, endDate] = dateRange;

    const [show, setShow] = useState('none');
    const handleShow = () => {
        if(show=='none') setShow('block');
        else setShow('none');
    }

    const [showForm, setShowForm] = useState('block');

    const esconder = useRef(null);

    const handleShowForm = () => {
        if(showForm=='none') {
            setShowForm('block');
            esconder.current.textContent = t("Esconder");
        }
        else {
            setShowForm('none');
            esconder.current.textContent = t("Buscar");
        }
    }

    let textopersonas = t("Personas");

    if(adultos>0 || ninos>0 || bebes>0)
    {
        textopersonas = "";
        if(adultos>0)
            textopersonas+=" "+adultos+" "+t("Adultos");
        if(ninos>0)
            textopersonas+=" "+ninos+" "+t("Niños");
        if(bebes>0)
            textopersonas+=" "+bebes+" "+t("Bebés");
    }

    const numpersonas = useRef(null);

    {/* ************* */}

    const [countAdults, setCountAdults] = useState(parseInt(adultos));
    const [countNinos, setCountNinos] = useState(parseInt(ninos));
    const [countBebes, setCountBebes] = useState(parseInt(bebes));

    const handleSumaAdultos = () => {

        if(countAdults+countNinos<capacidad_maxima)
        {
            setCountAdults(ca => ca+1);
            handleEtiqueta(parseInt(countAdults)+1, parseInt(countNinos), parseInt(countBebes));
        }
    }

    const handleRestaAdultos = () => {

        if(countAdults>0)
        {
            setCountAdults(ca => ca-1);
            handleEtiqueta(parseInt(countAdults)-1, parseInt(countNinos), parseInt(countBebes));
        }
    }

    const handleSumaNinos = () => {
        if(countNinos+countAdults<capacidad_maxima)
        {
            setCountNinos(cn => cn+1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos)+1, parseInt(countBebes));
        }
    }

    const handleRestaNinos = () => {
        if(countNinos>0)
        {
            setCountNinos(cn => cn-1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos)-1, parseInt(countBebes));
        }
    }

    const handleSumaBebes = () => {
        if(countBebes<capacidad_maxima)
        {
            setCountBebes(cb => cb + 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos), parseInt(countBebes)+1);
        }
    }

    const handleRestaBebes = () => {
        if(countBebes>0)
        {
            setCountBebes(cb => cb - 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos), parseInt(countBebes)-1);
        }
    }

    const handleEtiqueta = (nadults, nninos, nbebes) => {

        let frase = t("Personas");

        if(nadults>0) frase = " "+nadults+" "+t("Adultos");
        if(nninos>0) frase += " "+nninos+" "+t("Niños");
        if(nbebes>0) frase += " "+nbebes+" "+t("Bebés");

        numpersonas.current.textContent = frase;
    }

    let resultsd = [];
    let objeto0 =  {id: -1, name: t("Todos"), value: ""}
    resultsd = resultsd.concat(objeto0);
    let optionchecked = "Destinos";

    { destinos.forEach((undestino, index) => {
        let objeto =  {id: index, name: undestino.elnombre, value: undestino.laurl}
        if(undestino.laurl==destino) optionchecked = undestino.elnombre;
        resultsd = resultsd.concat(objeto);
        })
    }

    let resultstc = [];
    resultstc = resultstc.concat(objeto0);
    let optiontopocasa = "Tipo_Alojamiento";
    {tiposcasas.forEach((untipo, index) => {
        let objeto =  {id: index, name: untipo.nombre, value: untipo.id}
        if(untipo.id==tipocasa) optiontopocasa = t(untipo.nombre);
        resultstc = resultstc.concat(objeto);
    })}

    {/* ************* */}

    const [results4, setResults4] = useState(null);
    const [optioncheckedb, setOptioncheckedb] = useState("Todos");
    useEffect(() => {
        if(barrios.length>0)
        {
            let resultsbarrios =[];
            barrios.forEach((unbarrio, index) => {
                let objeto =  {id: index, name: unbarrio.elnombre, value: unbarrio.id}
                if(unbarrio.id==barrio) setOptioncheckedb(unbarrio.elnombre);
                resultsbarrios = resultsbarrios.concat(objeto);
            })
            setResults4(resultsbarrios);
        }
    },[])

    const buscabarrios = (destino) => {
        if(destino)
        {
            let token = document.getElementById('meta_token').getAttribute('content');

            const result = axios.post('/busca-barrios', {
                destino: destino
            },{
                headers: {
                    'content-type': 'text/json',
                    'X-CSRF-TOKEN': token
                }
            })
            .then((response) => {
                setResults4(null);
                let resultsbarrios =[];
                if (response.data.length >0)
                {
                    resultsbarrios = resultsbarrios.concat(objeto0);
                    { response.data.forEach((unbarrio, index) => {
                        let objeto =  {id: index, name: unbarrio.elnombre, value: unbarrio.id}
                        resultsbarrios = resultsbarrios.concat(objeto);
                    })}
                    setResults4(resultsbarrios);
                }
            })
        }
        else
        {
            setResults4(null);
        }
    }

    return (
        <Container className="caixa-cercadors">
            <div className="p-0 caixa-seccions solomovil fondo-buscador">
                <div className="row">
                    <div className="col buscador-inputs">
                        <Button  variant="primary" size="md" onClick={handleShowForm} style={{ background: ""+config.color_corporativo_web+"", border: "1px solid"+config.color_corporativo_web }} ref={esconder}>{t("Esconder")}</Button>
                    </div>
                </div>
            </div>
            <Form className="fondo-buscador caixa-seccions" method="get" action={"/"+preidioma+t("buscar2")} style={{display:showForm }}>
                <div className="row">
                    <div className="col buscador-inputs">
                        <CustomSelect
                            defaultText={ optionchecked }
                            optionsList= {resultsd}
                            nameInput="destino"
                            realInput={destino}
                            funcion = {buscabarrios}
                        />
                    </div>

                    {results4 && (
                        <div className="col buscador-inputs">
                            <CustomSelect
                                defaultText={ optioncheckedb }
                                optionsList= {results4}
                                nameInput="barrio"
                                realInput={barrio}
                            />
                        </div>
                    )}

                    {tiposcasas.length>1 ?
                        <div className="col buscador-inputs">
                            <CustomSelect
                                defaultText={optiontopocasa}
                                optionsList= {resultstc}
                                nameInput="tipo_casa"
                                realInput={tipocasa}
                            />
                        </div>
                        :
                        ""
                    }
                    <div className="col buscador-inputs">
                        <DatePicker
                            isClearable={true}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange={true}
                            monthsShown={2}
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            placeholderText={ t("Entrada - Salida") }
                            locale = {lang}
                            name="daterange"
                            className="form-control mw201 cpointer"
                        >
                        </DatePicker>
                    </div>
                    <div className="col buscador-inputs">
                        <div onClick={handleShow} className="form-control numpersonas mw201" ref={numpersonas}>{textopersonas}</div>
                        {/* ************ */}

                        <div className="formpersonas" style={{display:show }} onBlur={handleShow} tabIndex="0" onMouseLeave={handleShow}>
                            <div className="mt-2 row">
                                <label className="col-md-6 col-6 etiqueta-formulari"> { t("Adultos") }<div className="mini-etiqueta-formulari">({ t("Desde_12_años") })</div></label>
                                <div className="mt-2 col-md-4 col-6">
                                    <div className="mt-3 input-group">
                                        <span className="input-group-btn">
                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="minus" data-field="my_multi_select_adultos" onClick={ handleRestaAdultos}>
                                                <span className="centrar-boto-cercle">
                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{height:"1em", width: "1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                </span>
                                            </button>
                                        </span>
                                        <input type="text" name="adultos" className="form-control input-number numero-persones"  value = {countAdults} min="0" max={capacidad_maxima} id="numadultos"  readOnly={true} />
                                        <span className="input-group-btn">
                                            <button  type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_adultos"  onClick={handleSumaAdultos}>
                                                <span className="centrar-boto-cercle">
                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{height: "1em", width: "1em", display: "block", fill: "currentcolor" }} >
                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                    </svg>
                                                </span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="linia"></div>
                            <div className="mt-2 row">
                                <label className="col-md-6 col-6 etiqueta-formulari"> { t("Niños") }<div className="mini-etiqueta-formulari">({ t("De_2_a_12_años") })</div></label>
                                <div className="mt-2 col-md-4 col-6">
                                    <div className="mt-3 input-group">
                                        <span className="input-group-btn">
                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="minus" data-field="my_multi_select_ninos"  onClick={handleRestaNinos}>
                                                <span className="centrar-boto-cercle">
                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{height: "1em", width:"1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                </span>
                                            </button>
                                        </span>
                                        <input type="text" name="ninos" className="form-control input-number numero-persones"  value={countNinos} min="0" max={capacidad_maxima} id="numninos" readOnly={true} />
                                        <span className="input-group-btn">
                                            <button  type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_ninos" onClick={handleSumaNinos}>
                                                <span className="centrar-boto-cercle">
                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{height: "1em", width: "1em", display: "block", fill: "currentcolor"}}>
                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                    </svg>
                                                </span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="linia"></div>
                            <div className="mt-2 row">
                                <label className="col-md-6 col-6 etiqueta-formulari"> { t("Bebés") }<div className="mini-etiqueta-formulari">({ t("De 0 a 2 años") })</div></label>
                                <div className="mt-2 col-md-4 col-6">
                                    <div className="mt-3 input-group">
                                        <span className="input-group-btn">
                                            <button  type="button" className="btn btn-xs btn-number boto-cercle" datatype="minus" datafield="my_multi_select_bebes"  onClick={handleRestaBebes}>
                                                <span className="centrar-boto-cercle">
                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{height: "1em", width: "1em", display: "block", fill: "currentcolor"}}>
                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect>
                                                    </svg>
                                                </span>
                                            </button>
                                        </span>
                                        <input type="text" name="bebes" className="form-control input-number numero-persones"  value={countBebes} min="0" max={capacidad_maxima} id="numbebes" readOnly={true} />
                                        <span className="input-group-btn">
                                            <button  type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_bebes"  onClick={handleSumaBebes}>
                                                <span className="centrar-boto-cercle">
                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{height: "1em", width: "1em", display: "block",  fill: "currentcolor" }}>
                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                    </svg>
                                                </span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* ************ */}
                    </div>
                    <div className="col buscador-inputs">
                        <Button variant="primary" size="md" type="submit" style={{ background: ""+config.color_corporativo_web+"", border: "1px solid"+config.color_corporativo_web }}>
                            { t("Buscar") }
                        </Button>
                    </div>
                </div>
            </Form>
        </Container>
    );
}

export default Buscador;
