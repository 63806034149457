import { React, useState } from "react";
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const Content = ({ lang, apartur, contapartur, revforce, contrevforce, ajuntament, contajuntament }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const [showtitulo, setShowTitulo] = useState('block');

    const [showapartur, setShowApartur] = useState('none');
    const handleShowApartur = () => {
        if (showapartur == "none") {
            setShowApartur("block");
            setShowTitulo("none");
            setShowRevForce("none");
            setShowAjuntament("none");
        }
        else setShowApartur("none");
    }


    const [showrevforce, setShowRevForce] = useState('none');
    const handleShowRevForce = () => {
        if (showrevforce == "none") {
            setShowRevForce("block");
            setShowTitulo("none");
            setShowApartur("none")
            setShowAjuntament("none");
        }
        else setShowRevForce("none");
    }

    const [showajuntament, setShowAjuntament] = useState('none');
    const handleShowAjuntament = () => {
        if (showajuntament == "none") {
            setShowAjuntament("block");
            setShowTitulo("none");
            setShowApartur("none");
            setShowRevForce("none");
        }
        else setShowAjuntament("none");
    }

    const [show, setShow] = useState('none');
    const handleShow = () => {
        if (show == 'none') setShow('block');
        else setShow('none');
    };

    const handleSearch = (tipo, anyo) => {

        let token = document.getElementById('meta_token').getAttribute('content');

        let url = "";

        switch (tipo) {
            case "apartur": if (!anyo) {
                url = 'estapartur/total';
            }
            else {
                url = 'estapartur/anyo/' + anyo;
            }
                break;
            case "revforce": if (!anyo) {
                url = 'estrevforce/total';
            }
            else {
                url = 'estrevforce/anyo/' + anyo;
            }
                break;
            case "ajuntament": if (!anyo) {
                url = 'estajuntament/total';
            }
            else {
                url = 'estajuntament/anyo/' + anyo;
            }
                break;
        }

        const result = axios.get(url, {
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                let respuesta = "";
                let estadisticas = response.data.estadisticas;

                if (estadisticas.length > 0) {

                    for (var i = 0; i < estadisticas.length; i++) {
                        var undocument = estadisticas[i];

                        respuesta += "<div class='column mcb-column one-third column_column minh-200' style='border-bottom: 1px solid  #E87C0C'>";
                        respuesta += "    <h3 class='fs-18 lh-25'>";
                        respuesta += "        <img src='/images/apartur/corporation2-relations-pic2.png' width='30' height='36' class='imgestadisticas' />";
                        respuesta += undocument.nombre;
                        respuesta += "    </h3>";
                        respuesta += "<h6 style='font-weight: 400;'>";
                        respuesta += new Date(undocument.created_at).toLocaleDateString();
                        respuesta += "    </h6>";
                        respuesta += "    <div style='width:100%; padding-bottom:3px; display: inline-block; margin-bottom: 20px; line-height: 21px' class='fs-13'>";
                        respuesta += undocument.respuesta;
                        respuesta += "    </div>";
                        respuesta += "</div>";
                    }
                }

                switch (tipo) {
                    case 'apartur': document.querySelector(".eapartur").innerHTML = respuesta; break;
                    case 'revforce': document.querySelector(".erevforce").innerHTML = respuesta; break;
                    case 'ajuntament': document.querySelector(".eajuntament").innerHTML = respuesta; break;
                }
            })
    }


    return (
        <div id="Content">
            <div className="clearfix content_wrapper">
                <div className="sections_group">
                    <div className="entry-content">

                        <div className="section mcb-section no-margin-h no-margin-v">
                            <div className="section_wrapper mcb-section-inner submenuweb">
                                <div className="clearfix wrap mcb-wrap one-third valign-top move-up mTop-80W" id="positionDivWhite" data-mobile="no-up">
                                    <div className="mcb-wrap-inner">
                                        <div className="column mcb-column one column_image">
                                            <div className="image_frame image_item no_link scale-with-grid no_border">
                                                <div className="image_wrapper">
                                                    <img className="scale-with-grid" src="/images/apartur/shortcuts-icon1.png" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column mcb-column one column_divider">
                                            <hr className="no_line m40" />
                                        </div>

                                        <div className="column mcb-column one column_column ">
                                            <div className="clearfix column_attr">
                                                <h4 className="fs-25 h4M0 " >
                                                    <a onClick={handleShowApartur} className="curpointer">{t("APARTUR")}</a>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="clearfix wrap mcb-wrap one-third valign-top move-up mTop-80O" id="positionDivOange" data-mobile="no-up">
                                    <div className="mcb-wrap-inner">
                                        <div className="column mcb-column one column_image">
                                            <div className="image_frame image_item no_link scale-with-grid no_border">
                                                <div className="image_wrapper">
                                                    <img className="scale-with-grid" src="/images/apartur/shortcuts-icon3.png" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column mcb-column one column_divider">
                                            <hr className="no_line h4M0" />
                                        </div>

                                        <div className="column mcb-column one column_column">
                                            <div className="clearfix column_attr">
                                                <h4 className="fs-25 h4M0">
                                                    <a onClick={handleShowRevForce} className="curpointer">{t("REVFORCE")}</a>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="clearfix wrap mcb-wrap one-third valign-top move-up mTop-80G" id="positionDivGrey" data-mobile="no-up">
                                    <div className="mcb-wrap-inner">
                                        <div className="column mcb-column one column_image">
                                            <div className="image_frame image_item no_link scale-with-grid no_border">
                                                <div className="image_wrapper">
                                                    <img className="scale-with-grid" src="/images/apartur/shortcuts-icon4.png" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column mcb-column one column_divider">
                                            <hr className="no_line h4M0" />
                                        </div>

                                        <div className="column mcb-column one column_column">
                                            <div className="clearfix column_attr">
                                                <h4 className="fs-25 h4M0">
                                                    <a onClick={handleShowAjuntament} className="white curpointer">{t("AJUNTAMENT DE BARCELONA")}</a>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section" style={{ paddingTop: "60px" }}>
                            <div className="clearfix section_wrapper">
                                <div className="clearfix column_attr align_center" style={{ display: showtitulo }}>
                                    <h6 className="landing4-heading grey" style={{ paddingBottom: "3px", borderBottom: "1px solid #E87C0C", display: "inline-block", borderColor: "#E87C0C" }}>{t("Seleccione la sección a visualizar")}</h6>
                                </div>

                                {/* **** APARTUR ***** */}
                                <div className="sections_group normas" style={{ display: showapartur }}>

                                    <div className="section section-filters">
                                        <div className="clearfix section_wrapper">
                                            <div id="Filters" className="column one">
                                                <ul className="filters_buttons">
                                                    <li className="label">
                                                        {t("Filtros de búsqueda")}
                                                    </li>
                                                    <li className="anyos">
                                                        <a className="open" onClick={handleShow}><i className="fa-solid fa-page"></i>{t("Años")}<i className="fa-solid fa-caret-down"></i></a>
                                                    </li>
                                                    <li className="reset">
                                                        <a className="close" data-rel="*" onClick={() => { handleSearch("apartur", "") }}><i className="fa-solid fa-xmark"></i>{t("Ver todos")}</a>
                                                    </li>
                                                </ul>
                                                <div className="filters_wrapper" style={{ display: show }}>
                                                    <ul className="anyos" style={{ display: show }}>
                                                        <li className="reset-inner">
                                                            <a data-rel="*" onClick={() => { handleSearch("apartur", "") }}>{t("Ver todos")}</a>
                                                        </li>
                                                        {
                                                            contapartur.map((uncontador, index) => (
                                                                <li key={index}>
                                                                    <a data-rel={"." + uncontador.anyo} onClick={() => { handleSearch("apartur", uncontador.anyo) }}>{uncontador.anyo}({uncontador.total})</a>
                                                                </li>
                                                            ))
                                                        }
                                                        <li className="close">
                                                            <a onClick={handleShow} className="curpointer"><i className="fa-solid fa-xmark"></i></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="section">
                                        <div className="clearfix section_wrapper">

                                            <div className="clearfix mt-20 column_attr align_center">
                                                <h6 className="landing4-heading grey" style={{ paddingBottom: "3px", borderBottom: "1px solid  #E87C0C", display: "inline-block", borderColor: "#E87C0C" }}>{t("APARTUR")}</h6>
                                                <hr className="no_line" style={{ margin: "0 auto 15px auto" }} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="entry_content">
                                        <div className="section">
                                            <div className="clearfix section_wrapper">
                                                <div className="column one column_blog eapartur">
                                                    {
                                                        apartur.map((unaestadistica, index) => (
                                                            <div className="column mcb-column one-third column_column minh-200" style={{ borderBottom: "1px solid  #E87C0C" }} key={index}>
                                                                <h3 className="fs-18 lh-25">
                                                                    <img src="/images/apartur/corporation2-relations-pic2.png" width="30" height="36" className="imgestadisticas" />
                                                                    {unaestadistica.nombre}
                                                                </h3>
                                                                <h6 style={{ fontWeight: "400" }}>{new Date(unaestadistica.created_at).toLocaleDateString()}</h6>
                                                                <div style={{ width: "100%", paddingBottom: "3px", display: "inline-block", marginBottom: "20px", lineHeight: "21px" }} className="fs-13" dangerouslySetInnerHTML={{ __html: unaestadistica.respuesta }}>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ****FIN APARTUR ***** */}

                                {/* **** REVFORCE ***** */}
                                <div className="sections_group normas" style={{ display: showrevforce }}>

                                    <div className="section section-filters">
                                        <div className="clearfix section_wrapper">
                                            <div id="Filters" className="column one">
                                                <ul className="filters_buttons">
                                                    <li className="label">
                                                        {t("Filtros de búsqueda")}
                                                    </li>
                                                    <li className="anyos">
                                                        <a className="open" onClick={handleShow}><i className="fa-solid fa-page"></i>{t("Años")}<i className="fa-solid fa-caret-down"></i></a>
                                                    </li>
                                                    <li className="reset">
                                                        <a className="close" data-rel="*" onClick={() => { handleSearch("revforce", "") }}><i className="fa-solid fa-xmark"></i>{t("Ver todos")}</a>
                                                    </li>
                                                </ul>
                                                <div className="filters_wrapper" style={{ display: show }}>
                                                    <ul className="anyos" style={{ display: show }}>
                                                        <li className="reset-inner">
                                                            <a data-rel="*" onClick={() => { handleSearch("revforce", "") }}>{t("Ver todos")}</a>
                                                        </li>
                                                        {
                                                            contrevforce.map((uncontador, index) => (
                                                                <li key={index}>
                                                                    <a data-rel={"." + uncontador.anyo} onClick={() => { handleSearch("revforce", uncontador.anyo) }}>{uncontador.anyo}({uncontador.total})</a>
                                                                </li>
                                                            ))
                                                        }
                                                        <li className="close">
                                                            <a onClick={handleShow} className="curpointer"><i className="fa-solid fa-xmark"></i></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="section">
                                        <div className="clearfix section_wrapper">
                                            <div className="clearfix mt-20 column_attr align_center">
                                                <h6 className="landing4-heading grey" style={{ paddingBottom: "3px", borderBottom: "1px solid  #E87C0C", display: "inline-block", borderColor: "#E87C0C" }}>{t("REVFORCE")}</h6>
                                                <hr className="no_line" style={{ margin: "0 auto 15px auto" }} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="entry_content">
                                        <div className="section">
                                            <div className="clearfix section_wrapper">
                                                <div className="column one column_blog erevforce">
                                                    {
                                                        revforce.map((unaestadistica, index) => (
                                                            <div className="column mcb-column one-third column_column minh-200" style={{ borderBottom: "1px solid  #E87C0C" }} key={index}>
                                                                <h3 className="fs-18 lh-25">
                                                                    <img src="/images/apartur/corporation2-relations-pic2.png" width="30" height="36" className="imgestadisticas" />
                                                                    {unaestadistica.nombre}
                                                                </h3>
                                                                <h6 style={{ fontWeight: "400" }}>{new Date(unaestadistica.created_at).toLocaleDateString()}</h6>
                                                                <div style={{ width: "100%", paddingBottom: "3px", display: "inline-block", marginBottom: "20px", lineHeight: "21px" }} className="fs-13" dangerouslySetInnerHTML={{ __html: unaestadistica.respuesta }}>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* **** FIN REVFORCE ***** */}

                                {/* **** AJUNTAMENT ***** */}
                                <div className="sections_group normas" style={{ display: showajuntament }}>

                                    <div className="section section-filters">
                                        <div className="clearfix section_wrapper">
                                            <div id="Filters" className="column one">
                                                <ul className="filters_buttons">
                                                    <li className="label">
                                                        {t("Filtros de búsqueda")}
                                                    </li>
                                                    <li className="anyos">
                                                        <a className="open" onClick={handleShow}><i className="fa-solid fa-page"></i>{t("Años")}<i className="fa-solid fa-caret-down"></i></a>
                                                    </li>
                                                    <li className="reset">
                                                        <a className="close" data-rel="*" onClick={() => { handleSearch("ajuntament", "") }}><i className="fa-solid fa-xmark"></i>{t("Ver todos")}</a>
                                                    </li>
                                                </ul>
                                                <div className="filters_wrapper" style={{ display: show }}>
                                                    <ul className="anyos" style={{ display: show }}>
                                                        <li className="reset-inner">
                                                            <a data-rel="*" onClick={() => { handleSearch("ajuntament", "") }}>{t("Ver todos")}</a>
                                                        </li>
                                                        {
                                                            contajuntament.map((uncontador, index) => (
                                                                <li key={index}>
                                                                    <a data-rel={"." + uncontador.anyo} onClick={() => { handleSearch("ajuntament", uncontador.anyo) }}>{uncontador.anyo}({uncontador.total})</a>
                                                                </li>
                                                            ))
                                                        }
                                                        <li className="close">
                                                            <a onClick={handleShow} className="curpointer"><i className="fa-solid fa-xmark"></i></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="section">
                                        <div className="clearfix section_wrapper">
                                            <div className="clearfix mt-20 column_attr align_center">
                                                <h6 className="landing4-heading grey" style={{ paddingBottom: "3px", borderBottom: "1px solid  #E87C0C", display: "inline-block", borderColor: "#E87C0C" }}>{t("AJUNTAMENT DE BARCELONA")}</h6>
                                                <hr className="no_line" style={{ margin: "0 auto 15px auto" }} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="entry_content">
                                        <div className="section">
                                            <div className="clearfix section_wrapper">
                                                <div className="column one column_blog eajuntament">
                                                    {
                                                        ajuntament.map((unaestadistica, index) => (
                                                            <div className="column mcb-column one-third column_column minh-200" style={{ borderBottom: "1px solid  #E87C0C" }} key={index}>
                                                                <h3 className="fs-18 lh-25">
                                                                    <img src="/images/apartur/corporation2-relations-pic2.png" width="30" height="36" className="imgestadisticas" />
                                                                    {unaestadistica.nombre}
                                                                </h3>
                                                                <h6 style={{ fontWeight: "400" }}>{new Date(unaestadistica.created_at).toLocaleDateString()}</h6>
                                                                <div style={{ width: "100%", paddingBottom: "3px", display: "inline-block", marginBottom: "20px", lineHeight: "21px" }} className="fs-13" dangerouslySetInnerHTML={{ __html: unaestadistica.respuesta }}>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* **** FIN AJUNTAMENT ***** */}

                            </div>
                        </div>

                    </div>
                </div>
            </div >
        </div >
    );
}

export default Content;
